<template>

  <div>

    <p class="primary--text mt-6">{{$t('CHANNEL.PRICING_MODEL')}}</p>
    <v-row>
      <v-col cols="6">

        <div class="ma-0 d-flex">
          <v-col class="pa-0" cols="6">
            <v-switch class="mt-1"
            v-model="methods"
            value="credit"
            label="Crédits"
            />
          </v-col>
          <v-col class="pa-0" cols="3">
            <v-chip outlined class="mt-1" color="grey" label>{{$t('COMMON.FIXED')}}</v-chip>
          </v-col>
          <v-col class="pl-2 pa-0" cols="1">
            <v-input type="hidden"
            v-model="prices.credit"
            :value="1"
            @change="$emit('input', {prices:{credit:$event}})"
            />
          </v-col>
        </div>

        <div class="ma-0 d-flex">
          <v-col class="pa-0" cols="6">
            <v-switch class="mt-1"
            v-model="methods"
            value="organic"
            label="Organic"
            />
          </v-col>
          <v-col class="pa-0" cols="4">
            <v-chip outlined
            class="mt-1"
            color="grey"
            label>
            {{$t('COMMON.FREE')}}
          </v-chip>
          </v-col>
          <v-col class="pl-2 pa-0" cols="1">
            <v-input type="hidden"
            v-model="prices.organic"
            :value="0"
            @change="$emit('input', {prices:{organic:$event}})"
            />
          </v-col>
        </div>

      </v-col>
      <v-col cols="6">

        <div class="ma-0 d-flex">
          <v-col class="pa-0" cols="6">
            <v-switch class="mt-1"
            v-model="methods"
            value="cpa"
            label="CPA"
            />
          </v-col>
          <template v-if="methods.indexOf('cpa') !== -1" >
            <v-col class="pa-0" cols="4">
              <v-text-field
                v-model="prices.cpa"
                @change="$emit('input', {prices:{cpa:$event}})"
                :label="$t('COMMON.PRICE')"
                type="number"
                step="0.01"
                min="0.01"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col class="pl-2 pa-0" cols="1">
              <!-- <v-btn icon @click="$emit('input', {prices:{cpa: 0}})">
                <v-icon>mdi-backup-restore</v-icon>
              </v-btn> -->
            </v-col>
          </template>
        </div>

        <div class="ma-0 d-flex" >
          <v-col class="pa-0" cols="6">
            <v-switch class="mt-1"
            v-model="methods"
            value="cpc"
            label="CPC"
            />
          </v-col>
          <template v-if="methods.indexOf('cpc') !== -1" >
            <v-col class="pa-0" cols="4">
              <v-text-field
                v-model="prices.cpc"
                @change="$emit('input', {prices:{cpc:$event}})"
                :label="$t('COMMON.PRICE')"
                type="number"
                step="0.01"
                min="0.01"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col class="pl-2 pa-0" cols="1">
              <!-- <v-btn icon @click="$emit('input', {prices:{cpc: 0}})">
                <v-icon>mdi-backup-restore</v-icon>
              </v-btn> -->
            </v-col>
          </template>
        </div>

        <div class="ma-0 d-flex">
          <v-col class="pa-0" cols="6">
            <v-switch class="mt-1"
            v-model="methods"
            value="cpm"
            label="CPM"
            />
          </v-col>
          <template v-if="methods.indexOf('cpm') !== -1" >
            <v-col class="pa-0" cols="4">
              <v-text-field
                v-model="prices.cpm"
                @change="$emit('input', {prices:{cpm:$event}})"
                :label="$t('COMMON.PRICE')"
                type="number"
                step="0.01"
                min="0.01"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col class="pl-2 pa-0" cols="1">
              <!--
              <v-btn icon @click="$emit('input', {prices:{cpm: 0}})">
                <v-icon>mdi-backup-restore</v-icon>
              </v-btn>
              -->
            </v-col>
          </template>
        </div>

      </v-col>
    </v-row>

  </div>

</template>

<script>
export default {
  name:"FormChannelCompactPricing",
  data: () => ({
    valid: false,
    //
    methods: [],
    types: [],
    prices: {
      cpm:0,
      cpa:0,
      cpc:0,
      cpct:0,
      credit:1,
      organic:0,
    },
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    }
  },
  watch: {
    methods (n, o) {
      if (JSON.stringify(n || []) !== JSON.stringify(o || []))
        this.$emit('input', {methods: n})
    },
    values: {
      handler () {
        this.attribValues()
      },
      deep: true,
    }
  },
  created () {
    this.attribValues()
  },
  methods: {
    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        this[key] = values[key]
      })
    }
  }
}
</script>
